<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
      <el-breadcrumb-item>添加商品</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <!--  提示区域  -->
      <el-alert
          title="添加商品信息" :closable="false" type="info" center show-icon>
      </el-alert>
      <!--   步骤条区域   -->
      <el-steps :space="200" :active="activeIndex - 0" finish-status="success" align-center>
        <el-step title="基本信息"></el-step>
        <el-step title="商品规格"></el-step>
        <el-step title="商品金额"></el-step>
        <el-step title="商品图片"></el-step>
        <el-step title="商品信息"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <!--   form表单区   -->
      <el-form :model="editGoodsForm" :rules="editGoodsFormRules"
               ref="editGoodsFormRef" label-width="130px" class="demo-dynamic">
        <el-tabs v-model="activeIndex" :tab-position="tabPosition" style="height: auto;"
        :before-leave="beforeTabLeave" @tab-click="tabClicked">
          <el-tab-pane label="基本信息" name="0">
            <el-form-item label="商品名称：" prop="g_name">
              <el-input v-model="editGoodsForm.g_name"></el-input>
            </el-form-item>

            <el-form-item label="商品分类：" prop="c_id" >
              <!-- options:数据源        -->
              <el-cascader v-model="editGoodsForm.selectefKeys" :options="categorieslList" clearable
                           :props="cascaderProps" @change="parentCateChanged"></el-cascader>
            </el-form-item>
            <el-form-item label="计量单位：" prop="gu_id">
              <el-select v-model="editGoodsForm.gu_id" placeholder="请选择" clearable filterable>
                <el-option
                    v-for="item in goodsunitlList"
                    :key="item.gu_id"
                    :label="item.gu_name"
                    :value="item.gu_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="质量等级：" prop="ql_id">
              <el-select v-model="editGoodsForm.ql_id" placeholder="请选择" clearable filterable>
                <el-option
                    v-for="item in qualityleveList"
                    :key="item.ql_id"
                    :label="item.ql_name"
                    :value="item.ql_id">
                </el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item label="供应商：" prop="supplierlist">-->
<!--              <el-checkbox-group v-model="editGoodsForm.supplierlist">-->
<!--                <el-checkbox v-for="item in supplierList" @change="getcheckList"-->
<!--                             :key="item.s_id"-->
<!--                             :label="item.s_id">{{item.s_company_name}}</el-checkbox>-->
<!--              </el-checkbox-group>-->
<!--            </el-form-item>-->
            <el-form-item label="供应商：" >
              <el-select
                  v-model="editGoodsForm.supplierlist"
                  multiple @change="getcheckList"
                  filterable
                  placeholder="请选择供应商">
                <el-option
                    v-for="item in supplierList"
                    :key="item.s_id"
                    :label="item.s_company_name"
                    :value="item.s_id">
                </el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item label="仓库：" prop="warehouselist">-->
<!--              <el-cascader-->
<!--                  :options="TreeList" v-model="editGoodsForm.warehouselist"-->
<!--                  :props="Treeprops"-->
<!--                  clearable></el-cascader>-->
<!--            </el-form-item>-->
            <el-form-item label="商品备注：" prop="g_name">
              <el-input v-model="editGoodsForm.ga_describe"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="商品规格" name="1" class="Specifications">
            <el-form-item :label="item.gan_name" v-for="item in this.editGoodsForm.goodsattrlist" :key="item.gan_id">
<!--              <el-radio v-model="item.radio" :label="items.gav_id"  v-for="items in item.children" :key="items.gav_id">-->
<!--                {{ items.gav_name }}</el-radio>-->
              <el-select v-model="item.radio" placeholder="请选择" clearable filterable>
                <el-option
                    v-for="items in item.children"
                    :key="items.gav_id"
                    :label="items.gav_name"
                    :value="items.gav_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="商品金额" name="2">
            <el-form-item label="成本价：" prop="ga_cost_price">
              <el-input v-model="editGoodsForm.ga_cost_price" @blur="CostPrice" @keydown="handleInput2"></el-input>
            </el-form-item>
            <el-form-item label="市场价：" prop="ga_market_price">
              <el-input v-model="editGoodsForm.ga_market_price" ></el-input>
            </el-form-item>
            <el-form-item label="是否含税：" prop="">
              <el-select v-model="editGoodsForm.is_tax" placeholder="请选择">
                <el-option
                    v-for="item in istax"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安全库存：" prop="ga_security_stock">
              <el-input v-model.number="editGoodsForm.ga_security_stock"></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="ga_sort">
              <el-input v-model="editGoodsForm.ga_sort"></el-input>
            </el-form-item>
            <el-form-item label="开始时间：" prop="ga_start_date">
              <el-date-picker v-model="editGoodsForm.ga_start_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间：" prop="ga_end_date">
              <el-date-picker v-model="editGoodsForm.ga_end_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label='商品主图' prop="ga_image_see">
              <el-upload class="avatar-uploader" :headers="headersObj"
                  :action="action" :show-file-list="false"
                  :on-success="handleAvatarSuccess" >
                <div v-if="editGoodsForm.ga_image_see" class="upload-avatar">
                  <img  :src="editGoodsForm.ga_image_see">
                </div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div  v-if="editGoodsForm.ga_image_see" class="upload-delete" @click="edithandleRemove">
                <i class="el-icon-close"></i>
              </div>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="商品图片" name="3">
            <el-form-item>
              <el-upload
                  class="upload-demo" ref="upload" :headers="headersObj"
                  :action="action" :on-success="handSuccess" :file-list="imges"
                  :on-preview="handlePreview" :limit="5" :on-exceed="handExceed"
                  :on-remove="handleRemove" clearFiles multiple
                  list-type="picture">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>

          </el-tab-pane>
          <el-tab-pane label="商品信息" name="4">
            <template>
              <!-- bidirectional data binding（双向数据绑定） -->
              <quill-editor v-model="editGoodsForm.ga_remark"
                            ref="myQuillEditor"
                            class="ql-editor-class"
                            :options="editorOption">
              </quill-editor>
              <el-upload
                  style="display: none"
                  class="quill-picture-uploader"
                  :action="action"
                  :before-upload="bfUpload"
                  :on-success="uploadSuccess"
                  multiple
                  :headers="headersObj">
              </el-upload>
<!--              添加商品的按钮-->
              <el-button type="primary" class="addbutton" @click="addgood">修改商品</el-button>
            </template>
          </el-tab-pane>
        </el-tabs>


      </el-form>
    </el-card>
  </div>
</template>

<script>

export default {
  data() {
    var checkga_market_price = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('市场价不能为空'));
      }
      setTimeout(() => {
        if (!Number.parseFloat(value)) {
          callback(new Error('请输入正确的市场价'));
        } else {
          if (value < 0) {
            callback(new Error('市场价必须大于0'));
          } else {
            callback();
          }
        }
      }, 500);
    };
    var checkga_cost_price = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('成本价不能为空'));
      }
      setTimeout(() => {
        if (!Number.parseFloat(value)) {
          callback(new Error('请输入正确的成本价'));
        } else {
          if (value < 0) {
            callback(new Error('成本价必须大于0'));
          } else {
            callback();
          }
        }
      }, 500);
    };
    var checkga_security_stock = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('安全库存不能为空'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入正确的安全库存'));
        } else {
          if (value < 0) {
            callback(new Error('安全库存必须大于0'));
          } else {
            callback();
          }
        }
      }, 500);
    };
    const toolOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{'header': 1}, {'header': 2}],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],
      [{'indent': '-1'}, {'indent': '+1'}],
      [{'direction': 'rtl'}],
      [{'size': ['small', false, 'large', 'huge']}],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      [{'color': []}, {'background': []}],
      [{'font': []}],
      [{'align': []}],
      ['clean'],
      ['link', 'image', 'video']
    ];
    return{
      urlList:[],
      editorOption: {
        placeholder: "正文内容支持上传图片和视频",
        theme: "snow",
        modules: {
          toolbar: {
            container: toolOptions,
            handlers: {
              image: function (value) {    //替换原图片上传功能
                if (value) {
                  document.querySelector(".quill-picture-uploader input").click(); //核心
                } else {
                  this.quill.format("image", false);
                }
              },
            }
          },
        },
      },
      imgUrl:'',
      activeIndex:'0',
      tabPosition:'left',
      //商品分类list
      categorieslList:[],
      //计量列表
      goodsunitlList:[],
      //质量列表
      qualityleveList:[],
      //供应商列表
      supplierList:[],
      //选中供应商的数组
      checksupplierList: [],
      //选中的父级分类的ID数组
      // selectefKeys: [],
      //指定级联选择器的配置对象
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      //编辑的form表单
      editGoodsForm:{
        g_name:'',//商品名称
        c_id:0,//商品分类id
        gu_id:'',//计量单位id
        ql_id:'',//质量等级id
        ga_describe:'',
        goodsattrnamelist:[{name:'型号'}],
        ga_banners:[{name:''}],
        ga_remark:'',//商品描述
        supplierlist:[],//供应商关联商品数组
        warehouselist:[],//仓库数组
        ga_start_date:'',//开始时间
        ga_end_date:'', //结束时间
        ga_market_price:'',//市场价
        ga_cost_price:'',//成本价
        ga_security_stock:'',// 安全库存
        ga_sort:'0',//排序
        ga_image:'',//商品主图
        ga_image_see:''
      },

      editGoodsFormRules:{
        g_name:[
          {required: true, message: '请输入角色名称', trigger: 'blur'}
        ],
        c_id:[
          {required: true, message: '请选择商品分类', trigger: 'change'}
        ],
        gu_id:[
          {required: true, message: '请选择计量单位', trigger: 'change'}
        ],
        ql_id:[
          {required: true, message: '请选择质量等级', trigger: 'change'}
        ],
        supplierlist:[
          {required: true, message: '请选择供应商', trigger: 'change'}
        ],
        // warehouselist:[
        //   {required: true, message: '请选择仓库', trigger: 'change'}
        // ],
        ga_market_price:[
          {required: true, message: '请输入市场价', trigger: 'blur'},
          // {validator: checkga_market_price, trigger: 'blur'}
        ],
        ga_cost_price:[
          {required: true, message: '请输入成本价', trigger: 'blur'},
          // {validator: checkga_cost_price, trigger: 'blur'}
        ],
        ga_security_stock:[
          {required: true, message: '请输入安全库存', trigger: 'blur'},
          // {validator: checkga_security_stock, trigger: 'blur'}
        ],
        // ga_start_date:[
        //   {required: true, message: '请选择开始时间', trigger: 'change'}
        // ],
        // ga_end_date:[
        //   {required: true, message: '请选择结束时间', trigger: 'change'}
        // ],
        // ga_image_see:[
        //   {required: true, message: '请选择商品主图', trigger: 'change'}
        // ],
      },

      // 开始时间
      // ga_start_date: '',
      //默认的开始时间
      // ga_start_datevalue_start:'',
      // ga_start_datevalue_end:'',
      //上传图片
      headersObj:{
        Authorization:'Bearer ' + window.sessionStorage.getItem('token')
      },
      // action:'http://ofd.store.com/adminapi/upload/goods',
      action:process.env.VUE_APP_BASE_API+'/upload/goods',

      fileList:[],
      //修改分类图片的默认值
      imges:[],
      //规格
      enableSpec:null,

      addvalue:[],
      value1:'',
      TreeList:[],
      selectListarea:[],
      //仓库级联选择器配置
      Treeprops:{
        multiple: true,
        checkStrictly: true,
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'w_id',
        label: 'w_name',
        children: 'children'},
      //商品规格
      ParamsList:[],
      ga_banners:[],
      //是否含税
      istax:[{id:1,name:'否'},{id:2,name:'是'}]
      // radio:''
    }
  },


  methods: {
    async editgoods(id){
      const {data:res} = await this.$http.get(`goods/find/${this.$route.query.editid}`)
      if (res.code!=200) return this.$message.error(res.msg)
      this.editGoodsForm=res.data
      this.editGoodsForm.c_id = this.editGoodsForm.selectefKeys[this.editGoodsForm.selectefKeys.length - 1]
      this.imgUrl=res.data.ga_image_see

      this.imges=res.data.ga_banners
      this.editGoodsForm=res.data
    },

    // leClick(){
    //   console.log(this.global_model.ga_start_date)
    // },
    //监听图片上传成功的事件
    handSuccess(e, file, fileList) {
      if(fileList){
        this.fileList = fileList.map(res => {
          if(res.raw) {
            return {
              name: res.response.data.name,
              status: res.status,
              url: res.response.data.url,
              url_save:res.response.data.saveurl
            }
          }else {
            return {
              name: res.name,
              status: res.status,
              url: res.url,
              url_save: res.url_save
            }
          }
        })
      }

      console.log(this.fileList)
      this.editGoodsForm.ga_banners=this.fileList
    },
    //
    edithandleRemove(){
      this.editGoodsForm.ga_image_see=''
      this.editGoodsForm.ga_image=''
    },

    //处理图片预览效果
    handlePreview(file) {
      console.log(file);
    },
    //文件超出个数限制时的事件
    handExceed(files, fileList){
      console.log(files, fileList)
      this.$message.error('上传图片超出个数限制，请删除后重新上传')
    },
    //处理移除图片的操作
    handleRemove(file, fileList) {
      this.editGoodsForm.ga_banners.forEach((item,index)=>{
        if (file.name==item.name){
          this.editGoodsForm.ga_banners.splice(index,1)
        }

      })
    },
    //获取商品分类
    async getcustomerlevelList() {
      const {data: res} = await this.$http.get('category/getMainList/3')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      res.data.forEach(item=>{
        item.disabled= true
      })
      this.categorieslList = res.data
    },
    //获取计量列表
    async getgoodsunitList() {
      const {data: res} = await this.$http.get('goodsunit/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.goodsunitlList = res.data
    },
    //获取质量列表
    async getqualityleveList() {
      const {data: res} = await this.$http.get('qualityleve/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.qualityleveList = res.data
    },
    //获取供应商列表
    async getsupplierList() {
      const {data: res} = await this.$http.get('supplier/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.supplierList = res.data
    },
    //获取商品规格
    async getParamslist(){
      let con={"c_id":this.editGoodsForm.c_id}
      const {data:res} = await this.$http.get('goodsattrname/getMainList',
          {params:con})
      if (res.code!==200)return this.$message.error(res.msg)
      res.data.forEach(item=>{
        item.radio=''
      })
      this.ParamsList=res.data
      console.log(this.ParamsList)
    },
    //选择项发生变化触发这个函数
    parentCateChanged() {
      // this.editGoodsForm.c_id = this.selectefKeys[this.selectefKeys.length - 1]
      for (let i=0;i<this.categorieslList.length;i++){
        if (this.categorieslList[i].c_id==this.selectefKeys[0]){
          for (let j=0;j<this.categorieslList[i].children.length;j++){
            if (this.categorieslList[i].children[j].c_id==this.selectefKeys[1]){
              this.c_disocunt=this.categorieslList[i].children[j].c_discount
            }
          }
        }
      }
      this.editGoodsForm.c_id = this.editGoodsForm.selectefKeys[this.editGoodsForm.selectefKeys.length - 1]
    },
    getcheckList(){
      console.log(this.editGoodsForm.supplierlist)
      // this.editGoodsForm.supplierlist=this.checksupplierList
    },
    //阻止tab页签切换
    beforeTabLeave(activename,oldActivename){
      // console.log(activename,oldActivename)
      if (oldActivename==0 && this.editGoodsForm.selectefKeys.length!==3){
        this.$message.error('请先选择商品分类')
        return  false
      }
    },
    //tab切换调接口
    tabClicked(){
      if(this.activeIndex==='1'){
        this.getParamslist()
      }
      if(this.activeIndex==='2'){
        console.log(this.ParamsList)
      }
    },
    //点击提交
    addgood(){
      this.$refs.editGoodsFormRef.validate(async valid => {
        if (!valid) return
        // this.editGoodsForm.goodsattrlist=this.ParamsList
        const {data: res} = await this.$http.post('goods/edit',
            this.editGoodsForm)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },

    //展示仓库的对话框
    async showSetTreeListDialog() {
      //获取所有权限的数据
      const {data: res} = await this.$http.get('warehouse/getTreeList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.TreeList = res.data
    },

    //监听图片上传成功
    handleAvatarSuccess(res) {
      this.editGoodsForm.ga_image_see=res.data.url
      this.editGoodsForm.ga_image=res.data.saveurl
    },

    //规格结束——————————————————————————————————————————————————
    handleInput2(e) {
      // 通过正则过滤小数点后两位
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

    },
    CostPrice(){
      let nsb=this.editGoodsForm.ga_cost_price*1
      this.editGoodsForm.ga_cost_price=nsb.toFixed(2)
      if (this.c_disocunt==undefined){
        return false
      }else{
        let num=nsb*this.c_disocunt
        this.editGoodsForm.ga_market_price=num.toFixed(2)
      }
    },
    //上传图片之前校验
    bfUpload(file) {
      console.log(file)
      if ("image/png" == file.type || "image/jpeg" == file.type) {
        console.log(1)
      } else {
        this.$message.error('图片插入失败,请检查文件格式');
        return;
      }
    },
    //正文插入图片上传成功调用
    uploadSuccess(response, file, fileList) {
      console.log(response,123)
      this.urlList.push(response.url);
      let quill = this.$refs.myQuillEditor.quill;
      if (response.data.url != null) {
        console.log(response,456)
        //获取光标所在位置
        let length = quill.getSelection().index;
        //插入图片
        quill.insertEmbed(length, 'image', response.data.url);
        //移动光标到图片后
        quill.setSelection(length + 1);
      }
    },
  },
  created() {
    this.editgoods()
    this.getcustomerlevelList()
    this.getgoodsunitList()
    this.getqualityleveList()
    this.getsupplierList()
    this.showSetTreeListDialog()

  }
}
</script>

<style lang="less" scoped>
.addbutton{
  margin: 15px 0;
}
.el-upload-list__item{
  max-width: 300px;
}
.el-cascader{
  width: 100%;
}
.el-select{
  width: 100%;
}
</style>

